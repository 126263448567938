import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    total_count: 0,
    total_size: 0,
    result_list: null,
    result: null,
    isLoading: false,
    isSuccess: false,
    error: null
}

const StationSlice = createSlice({
    name: 'station',
    initialState,
    reducers: {
        getStation: state => {
            state.isLoading = true
        },
        getStationSuccess: (state, { payload }) => {
            const { result_list, total_count } = payload

            state.isLoading = false
            state.result_list = result_list
            state.total_count = total_count
        },
        getStationError: (state, { payload }) => {
            const { error } = payload

            state.isLoading = false
            state.error = error
        },
        getStationStatistic: state => {
            state.isLoading = true
        },
        getStationStatisticSuccess: (state, { payload }) => {
            state.isLoading = false
            state.result = payload
        },
        getStationStatisticError: (state, { payload }) => {
            const { error } = payload

            state.isLoading = false
            state.error = error
        },
        updateStation: state => {
            state.isLoading = true
        },
        updateStationSuccess: (state, { payload }) => {
            state.isLoading = false
            state.isSuccess = true
        },
        updateStationError: (state, { payload }) => {
            const { error } = payload
            state.isLoading = false
            state.error = error
        },
        configStation: state => {
            state.isLoading = true
        },
        configStationSuccess: (state, { payload }) => {
            state.isLoading = false
            state.isSuccess = true
        },
        configStationError: (state, { payload }) => {
            const { error } = payload
            state.isLoading = false
            state.error = error
        },
        resetStation: state => {
            state.isLoading = true
        },
        resetStationSuccess: (state, { payload }) => {
            state.isLoading = false
            state.isSuccess = true
        },
        resetStationError: (state, { payload }) => {
            const { error } = payload

            state.isLoading = false
            state.error = error
        },
        clearSignal: state => {
            state.isSuccess = false
            state.error = null
        },
        clearData: state => {
            state.isSuccess = false
            state.error = null
            state.total_count = 0
            state.total_size = 0
            state.result_list = null
            state.result = null
        }
    }
})

export const {
    getStation,
    getStationSuccess,
    getStationError,
    getStationStatistic,
    getStationStatisticSuccess,
    getStationStatisticError,
    clearSignal,
    clearData,
    updateStation,
    updateStationSuccess,
    updateStationError,
    configStation,
    configStationSuccess,
    configStationError,
    resetStation,
    resetStationSuccess,
    resetStationError
} = StationSlice.actions

export default StationSlice.reducer