import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import SVG from 'react-inlinesvg'

import { Backdrop, TransitionWrap, ModalWraper, DialogWraper, Waraper } from './styled/Alert'
import { Button } from '@utilities/components'

function Alert({ show = false, onHide, onAccept, topic = "Alert", message, theme = 'info', acceptTitle, cancleTitle, allowBlackdrop = false, hideBtn = false }) {

    const closeModal = () => {
        if(allowBlackdrop) {
            onHide(true)
        }
    }

    const SingleButton = ({type = "info"}) => {
        if(type == 'delete') {
            type = 'danger'
        }
        return (
            <div className='w-full'>
                <Button theme="secondary" rounded="lg" onClick={onHide}>
                    <span className="font-normal">
                        {cancleTitle ? cancleTitle : 'Cancle'}
                    </span>
                </Button>
            </div>
        )
    }

    const DoubleButton = ({type = "info"}) => {
        if(type == 'delete') {
            type = 'danger'
        }
        return (
            <div className='w-full flex justify-between gap-4'>
                <Button theme="secondary" rounded="lg" onClick={onHide}>
                    <span className="font-normal">
                        {cancleTitle ? cancleTitle : 'Cancle'}
                    </span>
                </Button>
                <Button theme={type} rounded="lg" onClick={onAccept}>
                    <span className="font-normal">
                        {acceptTitle ? acceptTitle : 'Ok'}
                    </span>
                </Button>
            </div>
        )
    }

    const GetIcon = (type) => {
        switch (type) {
            case 'warning':
                return '/media/svg/icons/code/Warning-1-circle.svg'
            case 'delete':
                return '/media/svg/icons/home/Trash.svg'
            case 'error':
                return '/media/svg/icons/code/Error-circle.svg'
            case 'success':
                return '/media/svg/icons/code/Done-circle.svg'
            default: return '/media/svg/icons/code/Question-circle.svg'
        }
    }

    const GetIconTheme = (type) => {
        switch (type) {
            case 'warning':
                return ' svg-icon-warning'
            case 'delete':
            case 'error':
                return ' svg-icon-danger'
            case 'success':
                return ' svg-icon-success'
            case 'secondary':
                return ' svg-icon-secondary'
            default: return ' svg-icon-info'
        }
    }

    return (
        <Transition appear show={show} as={Fragment}>
            <Waraper onClose={closeModal} zindex={1026}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Backdrop />
                </Transition.Child>
                <ModalWraper>
                    <TransitionWrap>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogWraper className="transform p-6 shadow-xl transition-all">
                                <div className='w-full flex justify-center'>
                                    <span className={`svg-icon${GetIconTheme(theme)} svg-icon-5x`}>
                                        <SVG src={toAbsoluteUrl(GetIcon(theme))} />
                                    </span>
                                </div>
                                <div className='w-full text-center mt-3'>
                                    <div>
                                        <span className='text-2xl font-bold'>{topic}</span>
                                    </div>

                                    {message &&
                                        <div className='mt-3 px-5'>
                                            <span className='text-sm'>
                                                {message}
                                            </span>
                                        </div>
                                    }

                                    <div className="mt-10">
                                        {hideBtn ?
                                            <SingleButton type={theme} />
                                            :
                                            <DoubleButton type={theme}/>
                                        }
                                    </div>
                                </div>
                            </DialogWraper>
                        </Transition.Child>
                    </TransitionWrap>
                </ModalWraper>
            </Waraper>
        </Transition>
    )
}

Alert.propTypes = {
    topic: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onAccept: PropTypes.func,
    theme: PropTypes.string,
    acceptTitle: PropTypes.string,
    cancleTitle: PropTypes.string,
    allowBlackdrop: PropTypes.bool,
    hideBtn: PropTypes.bool,
}

const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export default Alert