import axios from 'axios'

export const requestStation = () => axios.get(`v2/station`)
export const requestStationStatistic = ({station_id}) => axios.get(`v1/station/${station_id}/statistic`)
export const requestStationActivities = ({station_id}) => axios.get(`v1/station/${station_id}/activities`)
// export const requestUpdateStation = (customer) => axios.put(`v1/station`, customer)
// export const requestConfigStation = (customer) => axios.post(`v1/station/config`, customer)
export const requestResetStation = (station_id) => axios.put(`v1/station/${station_id}/reset`)

export const requestUpdateStation = (customer) => {
    let Config = axios.post(`v1/station/config`, customer)
    let Update = axios.put(`v1/station`, customer)

    return axios.all([Config, Update])
    // return axios.all([Config])
}