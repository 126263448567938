import React from 'react'
import ReactDOM from 'react-dom'
import App from 'app/App'
import axios from 'axios'
import { SplashScreen } from '@core/layout'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import './index.css'

import { store, persistor } from '@core/redux/configureStore'
import SetupAxios from '@core/datasource/SetupAxios'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')

SetupAxios(axios, store)

ReactDOM.render(
  <SplashScreen>
    <App store={store} persistor={persistor} basename={baseUrl}/>
  </SplashScreen>,
  rootElement)

serviceWorkerRegistration.unregister()
reportWebVitals()