import styled from 'styled-components'

const DropZone = styled.div`
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    color: #DBDBDB;
    border: 1.5px solid #E7E7E7;
    border-radius: 10px;
`

const DropZoneInput = styled.input`
    display: none;
`

const DropZoneThumb = styled.input`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ccc;
    background-size: cover;
    position: relative;
    cursor: pointer;
    
    &:after {
        content: attr(data-label);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 0;
        color: white;
        background: rgba(0, 0, 0, 0.75);
        text-align: center;
        font-size: 14px;
    }
`

export {
    DropZone,
    DropZoneInput,
    DropZoneThumb
}