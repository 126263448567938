import React, { useState } from 'react'
import { Clock } from '@utilities/components'

export function LayoutBase({ children }) {
    const [thisClock, setThisClock] = useState(0)
    return (
        <>
            <div className="flex flex-col flex-root">
                <div className="flex flex-row flex-column-fluid">
                    {/* Wrapper */}
                    <div className="flex flex-col flex-row-fluid w-full" id="cnyholding_wrapper">
                        {/* Content */}
                        <div className="flex flex-col flex-column-fluid overflow-x-auto backgroud-glassmorphism w-full" id="cnyholding_content">
                            <div className={`flex flex-column-fluid g${thisClock}`}>
                                <div className='hidden'><Clock setThisClock={setThisClock} /></div>
                                {children}
                            </div>
                        </div>
                        {/* END: Content */}
                    </div>
                    {/* END: Wrapper */}
                </div>
            </div>
        </>
    )
}