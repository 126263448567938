import { all, fork, takeLatest } from 'redux-saga/effects'
import { handleGetStation, handleGetStationStatistic, handleUpdateStation, handleResetStation } from '@core/redux/sagas/workers/station/StationHandler'
import { getStation, getStationStatistic, updateStation, configStation, resetStation } from '@core/redux/reducers/station/StationSlice'

function* watchGetStation() {
    yield takeLatest(getStation.type, handleGetStation)
}

function* watchGetStationStatistic() {
    yield takeLatest(getStationStatistic.type, handleGetStationStatistic)
}

function* watchUpdateStation() {
    yield takeLatest(updateStation.type, handleUpdateStation)
}

function* watchResetStation() {
    yield takeLatest(resetStation.type, handleResetStation)
}

export default function* StationWatcher() {
    const watchers = [
        watchGetStation,
        watchGetStationStatistic,
        watchUpdateStation,
        watchResetStation
    ]

    yield all(watchers.map((watcher) => fork(watcher)))
}