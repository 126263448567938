import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

import RootReducer from '@core/redux/reducers/RootReducer'
import RootSagas from '@core/redux/sagas/RootSagas'

const sagaMiddleware = createSagaMiddleware({
    onError: reportError,
    sagaMonitor: reportEvent
})

function reportError(e) {
    console.log(e)
}

function reportEvent(e) {
    console.log(e)
}

const store = configureStore({
    reducer: RootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true
    }).concat(sagaMiddleware)
})

const persistor = persistStore(store)

sagaMiddleware.run(RootSagas)

export {
    store,
    persistor
}