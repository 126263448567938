import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    total_count: 0,
    total_size: 0,
    result_list: null,
    result: null,
    isLoading: false,
    isSuccess: false,
    error: null
}

const ActivitiesSlice = createSlice({
    name: 'activities',
    initialState,
    reducers: {
        getActivities: state => {
            state.isLoading = true
        },
        getActivitiesSuccess: (state, { payload }) => {
            const { total_count, result_list } = payload
            state.isLoading = false
            state.result_list = result_list
        },
        getStationActivities: state => {
            state.isLoading = true
        },
        getStationActivitiesSuccess: (state, { payload }) => {
            state.isLoading = false
            state.result = payload
        },
        getStationActivitiesError: (state, { payload }) => {
            const { error } = payload

            state.isLoading = false
            state.error = error
        },
        CUDActivities: state => {
            state.isLoading = true
        },
        CUDActivitiesSuccess: (state, { payload }) => {
            state.isLoading = false
            state.isSuccess = true
        },
        CUDActivitiesError: (state, { payload }) => {
            const { error } = payload
            state.isLoading = false
            state.error = error
        },
        clearSignal: state => {
            state.isSuccess = false
            state.error = null
        },
        clearData: state => {
            state.isSuccess = false
            state.error = null
            state.total_count = 0
            state.total_size = 0
            state.result_list = null
            state.result = null
        }
    }
})

export const {
    getActivities,
    getActivitiesSuccess,
    getStationActivities,
    getStationActivitiesSuccess,
    getStationActivitiesError,
    CUDActivities,
    CUDActivitiesSuccess,
    CUDActivitiesError,
    clearSignal,
    clearData
} = ActivitiesSlice.actions

export default ActivitiesSlice.reducer