import styled from 'styled-components'

const LineButton = styled.button`
    border-radius: ${props => props.rounded ? props.rounded == 'none' ? '0px' : props.rounded == 'sm' ? '2px' : props.rounded == 'md' ? '6px' : props.rounded == 'lg' ? '8px' : props.rounded == 'xl' ? '12px' : props.rounded == 'full' ? '9999px' : '4px' : '0px'};
    background-color: #06C755;
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    &:hover {
        background-color: #05B34C;
    }
    &:active {
        background-color: #048B3B;
    }
    &:focus-visible {
        outline: none;
    }
`

export {
    LineButton
}