import axios from 'axios'

export const requestActivity = (id) => axios.get(`v1/sport/level/${id}`)
export const requestCreateActivity = (activity) => axios.post(`v1/sport`, activity, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})
export const requestUpdateActivity = (activity) => axios.put(`v1/sport`, activity, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})
export const requestDeleteActivity = (id) => axios.delete(`v1/sport/${id}`)
export const requestLevel = () => axios.get(`v1/level/experience`)