import { UNAUTHORIZED } from '@utilities/constants/AppConstant'

const SetupAxios = (axios, store) => {
    // axios.interceptors.request.use(
    //     config => {
    //         const { auth: { authToken } } = store.getState()

    //         if (authToken) {
    //             config.headers.Authorization = `Bearer ${authToken}`
    //         }

    //         return config
    //     },
    //     error => Promise.reject(error)
    // )

    // axios.interceptors.response.use(
    //     response => response,
    //     error => {
    //         const { status } = error.response

    //         if (status == UNAUTHORIZED) {
    //             console.warn('Unautorized')
    //         }

    //         return Promise.reject(error)
    //     }
    // )
}

export default SetupAxios