import { call, all, put } from "redux-saga/effects"
import { requestLevel } from '@core/datasource/network/Activity/Activity'
import { getLevelSuccess, getLevelError } from '@core/redux/reducers/station/LevelSlice'

export function* handleGetLevel() {
    try {
        const {
            data: { total_count, result_list }
        } = yield call(() => { return (requestLevel()) })

        yield put(getLevelSuccess({ total_count, result_list }));
    } catch (error) {
        yield put(getLevelError({ error }))
    }
}