import { all, fork, takeLatest } from 'redux-saga/effects'
import { handleSignOut } from '@core/redux/sagas/workers/auth/SignOutHandlers'
import { signOut } from '@core/redux/reducers/auth/SignOutSlice'

function* watchSignOut() {
    yield takeLatest(signOut.type, handleSignOut)
}

export default function* SignOutWatcher() {
    yield all([
        fork(watchSignOut)
    ])
}