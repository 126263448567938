import { call, put } from 'redux-saga/effects'
import { requestLogout } from '@core/datasource/network/auth/authentication'
import { signOutError, signOutSuccess } from '@core/redux/reducers/auth/SignOutSlice'

export function* handleSignOut() {
    try {
        yield call(() => requestLogout())

        yield put(signOutSuccess())
    } catch (error) {
        yield put(signOutError({ error }))
    }
}