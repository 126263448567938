import { call,all, put } from "redux-saga/effects"
import { requestStation, requestStationStatistic, requestUpdateStation, requestResetStation } from '@core/datasource/network/Station/Station'
import { getStationSuccess, getStationError, getStationStatisticSuccess, getStationStatisticError, updateStationSuccess, updateStationError, configStationSuccess, configStationError, resetStationSuccess, resetStationError } from '@core/redux/reducers/station/StationSlice'

export function* handleGetStation() {
    try {
        const {
            data: { total_count, result_list }
        } = yield call(() => { return (requestStation()) })

        yield put(getStationSuccess({ total_count, result_list }));
    } catch (error) {
        yield put(getStationError({ error }))
    }
}

export function* handleGetStationStatistic({ payload }) {
    try {
        const { data } = yield call(() => {
            return (requestStationStatistic(payload))
        })

        yield put(getStationStatisticSuccess(data));
    } catch (error) {
        yield put(getStationStatisticError({ error }))
    }
}

// export function* handleUpdateStation({ payload }) {
//     try {
//         const { station } = payload;
//         const data = yield call(() => requestUpdateStation(station));

//         yield put(updateStationSuccess(data));
//     } catch (error) {
//         yield put(updateStationError({ error }));
//     }
// }

export function* handleUpdateStation({ payload }) {
    try {
        const { station } = payload;
        const res = yield call(() => requestUpdateStation(station));

        yield all([
            put(configStationSuccess(res[0].data)),
            put(updateStationSuccess(res[1].data)),
        ])
    } catch (error) {
        yield put(updateStationError({ error }));
    }
}

export function* handleResetStation({ payload }) {
    try {
        const { stationId } = payload;
        const data = yield call(() => requestResetStation(stationId));
        console.log(data)
        yield put(resetStationSuccess(data));
    } catch (error) {
        console.log(error)
        yield put(resetStationError({ error }));
    }
}