import { call, put } from 'redux-saga/effects'
import Cookies from 'js-cookie'
import { requestLogin } from '@core/datasource/network/auth/authentication'
import { loginSuccess, loginError, logout } from '@core/redux/reducers/auth/authSlice'

export function* handleLogin(action) {
    const { email, password } = action.payload

    try {
        yield call(() => requestLogin({ provider: 'EMAIL', email, password }))

        const userCookies = Cookies.get('_USR.INFO')
        const user = JSON.parse(atob(userCookies))

        yield put(loginSuccess({ user }))
    } catch (error) {
        yield put(loginError({ error }))
    }
}

export function* handleGetInfomation() {

    try {
        const userCookies = Cookies.get('_USR.INFO')
        const user = JSON.parse(atob(userCookies))
       
        yield put(loginSuccess({ user }))
    } catch (error) {
        yield put(logout({ error }))
    }
}