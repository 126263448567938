import { combineReducers } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import MockupReducer from "./mockup/MockupSlice"
import StationReducer from "./station/StationSlice"
import authReducer from "./auth/authSlice"
import SignOutReducer from "./auth/SignOutSlice"
import ActivitiesReducer from "./station/ActivitiesSlice"
import LevelReducer from "./station/LevelSlice"

const RootReducer = combineReducers({
    mockup: persistReducer(
        {
            key: "WEATHERING:authentication",
            storage,
            whitelist: ["mockup"],
        },
        MockupReducer,
    ),
    station: StationReducer,
    auth: persistReducer({ key: "auth", storage, whitelist: ["user", "email", "password", "isRemember"] }, authReducer),
    signout: SignOutReducer,
    activities: ActivitiesReducer,
    level: LevelReducer
})

export default RootReducer