import styled, { css } from 'styled-components'

const RadioLabelStyled = styled.label`
    width: 100%;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    background: rgba(255, 255, 255, 0.8);

    & > div {
        padding-left: 2.5rem;
        padding-right: 0.25rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
`

const InputRadioStyled = styled.input`
    &:empty {
       display: none;

        & ~ ${RadioLabelStyled}:before {
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            content: '';
            width: 2.5em;
            background: #EFEFEF;
            border-radius: 10px 0 0 10px;
        }

        & ~ ${RadioLabelStyled} {
            position: relative;
            float: left;
            display:flex;        
            align-items: center;
            min-height: 3rem;  
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    &:hover { 
        ${props => !props.checked && css`          
            & ~ ${RadioLabelStyled} {
                color: #888;
            }

            & ~ ${RadioLabelStyled}:before {
                content:'\\2714';
                display: flex;
                text-indent: .9em;
                align-items: center;
                color: #C2C2C2;
            }`
    }
    }

    &:checked {
        & ~ ${RadioLabelStyled}:before {
            content:'\\2714';
            text-indent: .9em;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            background-color: #28388F;
        }
    }

    &:focus {
        & ~ ${RadioLabelStyled}:before {
            
        }
    }
  `

const InputRadio = (props) => <InputRadioStyled {...props} />
const RadioLabel = (props) => <RadioLabelStyled {...props} />

export {
    InputRadio,
    RadioLabel
}