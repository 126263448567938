import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'

import { Backdrop, TransitionWrap, ModalWraper, DialogWraper, Waraper } from './styled/Modal'

function Modal({ isOpen, onHide, sizing, children, allowBlackdrop = false }) {
    let refFocusComponent = useRef(null)

    const closeModal = () => {
        if (allowBlackdrop) {
            onHide(false)
        }
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Waraper initialFocus={refFocusComponent} onClose={closeModal} zindex={1026}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Backdrop />
                </Transition.Child>
                <ModalWraper ref={refFocusComponent}>
                    <TransitionWrap>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogWraper className="transform p-6 shadow-xl transition-all" sizing={sizing}>
                                {children}
                            </DialogWraper>
                        </Transition.Child>
                    </TransitionWrap>
                </ModalWraper>
            </Waraper>
        </Transition>
    )
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}

export default Modal