import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isLoading: false,
    isSuccess: false,
    error: null
}

const SignOutReducer = createSlice({
    name: "signout",
    initialState,
    reducers: {
        signOut: state => {
            state.isLoading = true
        },
        signOutSuccess: state => {
            state.isLoading = false
            state.isSuccess = true
        },
        signOutError: (state, { payload }) => {
            const { error } = payload

            state.isLoading = false
            state.error = error
        },
        clearSignal: state => {
            state.isSuccess = false
            state.error = null
        }
    }
})

export const { signOut, signOutSuccess, signOutError, clearSignal } = SignOutReducer.actions
export default SignOutReducer.reducer