import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    error: null
}

const MockupSlice = createSlice({
    name: 'mockup',
    initialState,
    reducers: {
        mockups: state => {
            state.isLoading = true;
        },
        mockupsSuccess: (state, action) => {
            state.isLoading = false
            state.error = null
        },
        mockupsError: (state, action) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})

export const {
    mockups,
    mockupsSuccess,
    mockupsError
} = MockupSlice.actions

export default MockupSlice.reducer