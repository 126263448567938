import styled from 'styled-components'
import PropTypes from 'prop-types'

const InputStyled = styled.input`
    border-radius: ${props => props.rounded ? props.rounded == 'none' ? '0px' : props.rounded == 'sm' ? '2px' : props.rounded == 'md' ? '6px' : props.rounded == 'lg' ? '8px' : props.rounded == 'xl' ? '12px' : props.rounded == 'full' ? '9999px' : '4px' : '0px'};
    margin-top: 3px;
    margin-bottom: 3px;
    border-width: 1px;
    border-color: ${ props => props.theme ? Theme(props.theme).color : '#6071d2'};
    padding-left: ${ props => Size(props.size).left };
    padding-right: ${ props => Size(props.size).right };
    padding-top: ${ props => Size(props.size).top };
    padding-bottom: ${ props => Size(props.size).bottom };
    font-size: ${ props => Size(props.size).font };
    width: ${ props => props.width && props.width != "" ? props.width : '100%'};
    &:hover {
        border-width: 1px;
        border-color: ${ props => props.theme ? Theme(props.theme).hover : '#28388F'};
    }
    &:focus {
        outline: none;
        border-width: 1px;
        border-color: ${ props => props.theme ? Theme(props.theme).focus : '#28388F'};
    }
`

const Input = (props) => <InputStyled {...props} />

Input.propTypes = {
    rounded: PropTypes.string,
    theme: PropTypes.string
}

function Size(size){
    let rs = {
        font: '14px',
        left: '12px',
        right: '12px',
        top: '10px',
        bottom: '10px'
    }

    if(size == "sm") {
        rs.font = '12px'
        rs.left = '10px'
        rs.right = '10px'
        rs.top = '8px'
        rs.bottom = '8px'
    } else if(size == "lg") {
        rs.font = '16px'
        rs.left = '14px'
        rs.right = '14px'
        rs.top = '12px'
        rs.bottom = '12px'
    }

    return rs
}

function Theme(theme){
    let rs = {
        font: 'black',
        color: '',
        hover: '',
        focus: '',
        active: ''
    }

    if(theme == "success") {
        rs.color = '#D8D8D8'
        rs.hover = 'rgb(74, 222, 128)'
        rs.focus = 'rgb(74, 222, 128)'
    } else if(theme == "warning") {
        rs.color = '#D8D8D8'
        rs.hover = 'rgb(251, 146, 60)'
        rs.focus = 'rgb(251, 146, 60)'
    } else if(theme == "danger") {
        rs.color = '#D8D8D8'
        rs.hover = 'rgb(248, 113, 113)'
        rs.focus = 'rgb(248, 113, 113)'
    } else if(theme == "primary") {
        rs.color = '#D8D8D8'
        rs.hover = 'rgb(96, 165, 250)'
        rs.focus = 'rgb(96, 165, 250)'
    } else if(theme == "feni") {
        rs.color = '#D8D8D8'
        rs.hover = '#28388F'
        rs.focus = '#28388F'
    } else {
        rs.hover = '#D8D8D8'
        rs.focus = '#D8D8D8'
    }
    return rs
}

export {
    Input
}