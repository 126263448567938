import styled from 'styled-components'
import PropTypes from 'prop-types'

const ButtonStyled = styled.button`
    border-radius: ${props => props.rounded ? props.rounded == 'none' ? '0px' : props.rounded == 'sm' ? '2px' : props.rounded == 'md' ? '6px' : props.rounded == 'lg' ? '8px' : props.rounded == 'xl' ? '12px' : props.rounded == 'full' ? '9999px' : '4px' : '0px'};
    background-color: ${props => Theme(props.theme).color};
    color: ${props => Theme(props.theme).font};
    padding-left: ${props => props.paddingL && props.paddingL != "" ? props.paddingL : '12px'};
    padding-right: ${props => props.paddingR && props.paddingR != "" ? props.paddingR : '12px'};
    padding-top: ${props => props.paddingT && props.paddingT != "" ? props.paddingT : '10px'};
    padding-bottom: ${props => props.paddingB && props.paddingB != "" ? props.paddingB : '10px'};
    width: ${props => props.width && props.width != "" ? props.width : '100%'};
    height: ${props => props.height && props.height != "" ? props.height : ''};
    font-weight: bold;
    font-size: 16px;
    text-align: center;

    & svg {
        fill: ${props => Theme(props.theme).font};
    }

    &:hover {
        background-color: ${props => Theme(props.theme).hover};
    }
    &:active {
        background-color: ${props => Theme(props.theme).active};
    }
    &:focus-visible {
        outline: none;
    }
`
const Button = (props) => <ButtonStyled {...props} />

Button.propTypes = {
    rounded: PropTypes.string,
    theme: PropTypes.string,
    paddingL: PropTypes.string,
    paddingR: PropTypes.string,
    paddingT: PropTypes.string,
    paddingB: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
}

function Theme(theme) {
    let rs = {
        font: 'black',
        color: '',
        hover: '',
        focus: '',
        active: ''
    }

    if (theme == "success") {
        rs.font = 'white'
        rs.color = '#1BC5BD'
        rs.hover = '#0BB7AF'
        rs.focus = '#0BB7AF'
    } else if (theme == "warning") {
        rs.font = 'white'
        rs.color = '#FFA800'
        rs.hover = '#EE9D01'
        rs.focus = '#EE9D01'
    } else if (theme == "danger") {
        rs.font = 'white'
        rs.color = '#F64E60'
        rs.hover = '#EE2D41'
        rs.focus = '#EE2D41'
    } else if (theme == "primary") {
        rs.font = 'white'
        rs.color = '#6993FF'
        rs.hover = '#4A7DFF'
        rs.focus = '#4A7DFF'
    } else if (theme == "feni") {
        rs.font = 'white'
        rs.color = '#28388F'
        rs.hover = '#222f77'
        rs.focus = '#161f50'
        rs.active = '#161f50'
    } else if (theme == "secondary") {
        rs.color = '#D8D8D8'
        rs.hover = '#ADADAD'
        rs.focus = '#ADADAD'
        rs.active = '#ADADAD'
    } else if (theme == "info") {
        rs.font = '#FFFFFF'
        rs.color = '#8950FC'
        rs.hover = '#7337EE'
        rs.focus = '#7337EE'
    } else {
        rs.color = '#E8E8E8'
        rs.hover = '#D8D8D8'
        rs.focus = '#D8D8D8'
    }
    return rs
}

export {
    Button
}