import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    email: '',
    password: '',
    isRemember: false,
    user: undefined,
    isLoading: false,
    error: null
};

const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: state => {
            state.isLoading = true;
        },
        loginSuccess: (state, action) => {
            state.isLoading = false
            state.user = action.payload.user
            state.error = null
        },
        logout: (state) => {
            state.user = undefined
            state.isLoading = false
        }, 
        loginError: (state, action) => {
            state.isLoading = false
            state.error = action.payload
        },
        infomation: state => {
            state.isLoading = true;
        },
        rememberMe: (state, action) => {
            state.isRemember = !state.isRemember

            if (state.isRemember) {
                state.email = action.payload.email
                state.password = action.payload.password
            } else {
                state.email = ''
                state.password = ''
            }
        }
    }
});

export const {
    login,
    loginSuccess,
    logout,
    loginError,
    rememberMe,
    infomation
} = authReducer.actions

export default authReducer.reducer