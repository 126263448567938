import { call, put } from "redux-saga/effects"
import { requestStationActivities } from '@core/datasource/network/Station/Station'
import { requestActivity, requestCreateActivity, requestUpdateActivity, requestDeleteActivity } from '@core/datasource/network/Activity/Activity'
import { getActivitiesSuccess, CUDActivitiesSuccess, CUDActivitiesError, getStationActivitiesError, getStationActivitiesSuccess } from '@core/redux/reducers/station/ActivitiesSlice'

export function* handleGetStationActivities({ payload }) {
    try {
        const {
            data
        } = yield call(() => { return (requestStationActivities(payload)) })

        yield put(getStationActivitiesSuccess(data));
    } catch (error) {
        yield put(getStationActivitiesError({ error }))
    }
}

export function* handleGetActivities({ payload }) {
    try {
        const { id } = payload;
        const {
            data
        } = yield call(() => { return (requestActivity(id)) })

        yield put(getActivitiesSuccess(data));
    } catch (error) {
        yield put(getStationActivitiesError({ error }))
    }
}

export function* handleCUDActivity({ payload }) {
    try {
        const { method, activity, id } = payload;
        const { data } = yield call(() => {
            return method === "POST"
                ? requestCreateActivity(activity)
                : method === "PUT"
                    ? requestUpdateActivity(activity)
                    : method === "DELETE"
                    ? requestDeleteActivity(id)
                    : null
        });

        yield put(CUDActivitiesSuccess(data));
    } catch (error) {
        yield put(CUDActivitiesError({ error }));
    }
}