import { all, fork, takeLatest } from 'redux-saga/effects'
import { handleGetLevel } from '@core/redux/sagas/workers/station/LevelHandler'
import { getLevel } from '@core/redux/reducers/station/LevelSlice'

function* watchGetLevel() {
    yield takeLatest(getLevel.type, handleGetLevel)
}

export default function* LevelWatcher() {
    const watchers = [
        watchGetLevel
    ]

    yield all(watchers.map((watcher) => fork(watcher)))
}