import styled from 'styled-components'

const Aside = styled.div`
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    & ul li {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 20px;
        padding-right: 20px;
        color: #565656;
        // border-bottom: 1px solid #f2f2f2;
    }

    & ul li span{
        margin-top: auto;
        margin-bottom: auto;
    }

    & ul li svg{
        fill: #565656;
        margin-right: 10px
    }

    & ul li:hover {
        background-color: #f2f2f2;
    }

    & ul li.active {
        color: #28388F;
        padding-left: 15px;
        border-left: 5px solid #28388F;
    }

    & ul li.active svg{
        fill: #28388F;
    }

    & ul li.danger {
        color: red;
    }

    & ul li.danger svg{
        fill: red;
    }

`

export {
    Aside
}