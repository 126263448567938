import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const CircleGroupStyled = styled.div`
    position: absolute;
    height: ${props => props.height ? `${props.height}` : '350px' };
    width: ${props => props.width ? `${props.width}` : '350px' };
    max-width: ${props => props.maxWidth ? `${props.maxWidth}` : '100%' };
    top: ${props => props.top ? `${props.top}` : '50%' };
    left: ${props => props.left ? `${props.left}` : '50%' };
    transform: translate(${props => props.tranX ? `${props.tranX}` : '-50%' }, ${props => props.tranY ? `${props.tranY}` : '-50%' });
`

const CircleThemeStyled = styled.div`
    position: absolute;
    border-radius: ${props => props.radius ? `${props.radius}` : '50%' };
    background: linear-gradient(${props => props.degrees ? `${props.degrees}deg` : '45deg' }, ${props => props.colorStart ? `${props.colorStart}` : '#006db3' }, ${props => props.colorEnd ? `${props.colorEnd}` : '#29b6f6' });
`

const CircleStyled = styled(CircleThemeStyled)`
    height: ${props => props.height ? `${props.height}` : '100px' };
    width: ${props => props.width ? `${props.width}` : '100px' };

    ${(props) => { 
        switch (props.position) { 
            case "bottom":
                return css`
                    bottom: ${props => props.top ? `${props.top}` : '0' };
                    ${props => props.left ? `left:${props.left};` : '' }
                    ${props => props.right ? `right:${props.right}` : '' }
                `;
              default:
                return css`
                    top: ${props => props.top ? `${props.top}` : '0' };
                    ${props => props.left ? `left:${props.left};` : '' }
                    ${props => props.right ? `right:${props.right}` : '' }
                `;
        }
    }}
`

const CircleGroup = (props) => <CircleGroupStyled {...props} />
const CircleTheme = (props) => <CircleThemeStyled {...props} />
const Circle = (props) => <CircleStyled {...props} />

CircleGroup.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    top: PropTypes.string,
    left: PropTypes.string,
    tranX: PropTypes.string,
    tranY: PropTypes.string
}

CircleTheme.propTypes = {
    radius: PropTypes.string,
    degrees: PropTypes.number,
    colorStart: PropTypes.string,
    colorEnd: PropTypes.string
}

Circle.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    position: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
    left: PropTypes.string
}

export {
    CircleGroup,
    CircleTheme,
    Circle
}