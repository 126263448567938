import React, { Suspense, lazy, useEffect } from "react"
import { useDispatch, shallowEqual, useSelector } from "react-redux"
import { Redirect, Switch, Route } from "react-router-dom"
import { LayoutBase } from '@core/layout/components/LayoutBase'
import { infomation } from '@core/redux/reducers/auth/authSlice'
const Weathering = lazy(() =>
    import("app/modules/Weathering/Weathering")
)

const Login = lazy(() =>
    import("app/modules/Login/Login")
)

const Logout = lazy(() =>
    import("app/modules/Logout/Logout")
)

const Admin = lazy(() =>
    import("app/modules/Admin/Admin")
)

const Activity = lazy(() =>
    import("app/modules/Admin/Activity")
)

export default function BaseRoute() {
    // Checked User Authentication
    const dispatch = useDispatch()
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != undefined && auth.user != null,
        }),
        shallowEqual
    )

    useEffect(() => {
        dispatch(infomation())
    }, [])

    console.log(isAuthorized)
    return (
        <Suspense>
            <LayoutBase>
                <Switch>
                    <Redirect exact from="/" to="/weather" />
                    <Route path="/weather" component={Weathering} />
                    <Route path="/logout" component={Logout} />
                    {
                        isAuthorized ?
                            <>
                                <Route path="/admin/station" component={Admin} />
                                <Route path="/admin/activity" component={Activity} />
                                <Redirect exact from="/login" to="/admin" />
                                <Redirect exact from="/admin" to="/admin/station" />
                            </>
                            :
                            <>
                                <Route path="/login" component={Login} />
                                <Redirect exact from="/admin/station" to="/admin" />
                                <Redirect exact from="/admin/activity" to="/admin" />
                                <Redirect exact from="/admin" to="/login" />
                            </>
                    }
                </Switch>
            </LayoutBase>
        </Suspense>
    );
}
