import React, { Suspense } from 'react'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Routes } from 'app/Routes'
import { BrowserRouter } from 'react-router-dom'
import { LayoutSplashScreen } from '@core/layout'

// import context
import RBACProvider from '@core/context/RBACContext'

export default function App({ store, persistor, basename }) {  return (
    <Provider store={store}>
      <PersistGate loading={<LayoutSplashScreen />} persistor={persistor}>
        <Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter basename={basename}>
            <RBACProvider>
              <Routes />
            </RBACProvider>
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  )
}
