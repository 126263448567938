import React from 'react'

class Clock extends React.Component {
    constructor(props) {
        super(props)
        var date = this.getTimeString()
        if(props.setThisClock) props.setThisClock(this.getHourString())
        this.state = {
            time: date
        }
    }

    getTimeString() {
        const date = new Date(Date.now()).toLocaleTimeString('th-TH', { hour12: false, hour: '2-digit', minute: '2-digit' })

        return date
    }

    getHourString() {
        const date = new Date(Date.now()).toLocaleTimeString('th-TH', { hour12: false, hour: '2-digit' })

        return date
    }

    componentDidMount() {
        const _this = this

        this.timer = setInterval(function () {
            var date = _this.getTimeString()
            if(_this.props.setThisClock) _this.props.setThisClock(_this.getHourString())
            _this.setState({
                time: date
            })

        }, 1000)
    }
    componentWillUnmount() {
        clearInterval(this.timer)
    }

    render() {
        return (
            <p>{this.state.time}</p>
        )
    }
}

export default Clock
