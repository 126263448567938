import { all, fork } from "redux-saga/effects"

import MockupWatcher from "./watchers/mockup/MockupWatcher"
import StationWatcher from "./watchers/station/StationWatcher"
import authWatcher from "./watchers/auth/authWatcher"
import SignOutWatcher from "./watchers/auth/signOutWatcher"
import ActivitiesWatcher from "./watchers/station/ActivitiesWatcher"
import LevelWatcher from "./watchers/station/LevelWatcher"

export default function* RootSaga() {
    const watchers = [
        MockupWatcher,
        StationWatcher,
        authWatcher,
        SignOutWatcher,
        ActivitiesWatcher,
        LevelWatcher
    ]
  
    yield all(watchers.map((watcher) => fork(watcher)))
}  