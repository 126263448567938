import { all, fork, takeLatest } from 'redux-saga/effects'
import { handleMockup } from '@core/redux/sagas/workers/mockup/MockupHandlers'
import { mockups } from '@core/redux/reducers/mockup/MockupSlice'

function* watchMockup() {
    yield takeLatest(mockups.type, handleMockup)
}

export default function* MockupWatcher() {
    yield all([
        fork(watchMockup)
    ])
}