import { all, fork, takeLatest } from 'redux-saga/effects'
import { handleLogin, handleGetInfomation } from '@core/redux/sagas/workers/auth/authHandlers'
import { login, infomation } from '@core/redux/reducers/auth/authSlice'

function* watchLogin() {
    yield takeLatest(login.type, handleLogin)
}

function* watchInfomation() {
    yield takeLatest(infomation.type, handleGetInfomation)
}

export default function* authWatcher() {
    const watchers = [
        watchLogin,
        watchInfomation
    ]

    yield all(watchers.map((watcher) => fork(watcher)))
}