import styled from 'styled-components'
import { Dialog } from '@headlessui/react'

const Waraper = styled(Dialog)`
    position: relative;
    z-index: ${props => props.zindex ? `${props.zindex}` : '10'};
`

const Insert = styled.div`
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
`
const ModalWraper = styled(Insert)`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`

const Backdrop = styled(Insert)`    
    top: 0;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
`

const TransitionWrap = styled.div`
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
`

const DialogWraper = styled(Dialog.Panel)`
    overflow: hidden;
    background: rgba(255, 255, 255, 1);
    vertical-align: middle;
    text-align: left;
    border-radius: 1rem;
    width: 100%;
    max-width: ${props => props.sizing ? getWraperWidth(props.sizing) : '500px'};
    @media (max-width: 768px) {
        max-width: 100%;
    }
`

function getWraperWidth(type) {
    switch (type) {
        case 'sm':
            return '300px'
        case 'md':
            return '500px'
        case 'lg':
            return '800px'
        case 'xl':
            return '1140px'
        case 'full':
            return '100%'
        case '80%':
            return '80%'
        default:
            return '500px'
    }
}

export {
    Backdrop,
    Waraper,
    ModalWraper,
    DialogWraper,
    TransitionWrap
}