import styled from 'styled-components'
import PropTypes from 'prop-types'

const CardGlassContainerStyled = styled.div`
    position: relative;    
    min-height: ${props => props.height ? `${props.height}` : '200px'};
    width: ${props => props.width ? `${props.width}` : '450px'};
    border-radius: ${props => props.radius ? `${props.radius}` : '20px'};
    background: rgba(255, 255, 255, ${props => props.opacity ? `${props.opacity}` : '0.8'});
    backdrop-filter: blur(30px);
    border: 2px solid rgba(255, 255, 255, 1);
    box-shadow: 0 0 20px rgba(0, 0, 0, ${props => props.shodow ? `${props.shodow}` : '0'});

    @media screen and (max-width: 992px) {
        width: 100%;
    } 
    @media screen and (max-width: 768px) {
        width: 100%;
    }    
    @media screen and (max-width: 600px) {
        width: 100%;
    }
    @media screen and (max-width: 450px) {
        width: 100%;
    }
`

const CardStyled = styled.div`
    position: relative;
    min-height: 10px;
    min-width: 10px;
    width: ${props => props.width ? `${props.width}` : '100%'};
    height: ${props => props.height ? `${props.height}` : 'auto'};
    max-width: 100%;
    border-radius: ${props => props.radius ? `${props.radius}` : '20px'};
    background: ${props => props.background ? `${props.background}` : 'white'};
    border: ${props => props.border ? `${props.border}` : '1px'};
    box-shadow: 0 0 20px rgba(0, 0, 0, ${props => props.shadow ? `${props.shadow}` : '0'});
`

const CardGlassContainer = (props, {...rest}) => <CardGlassContainerStyled {...props} {...rest} />
const Card = (props) => <CardStyled {...props} />

CardGlassContainer.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    radius: PropTypes.string,
    opacity: PropTypes.string,
    shodow: PropTypes.string,
}

Card.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    radius: PropTypes.string,
    shodow: PropTypes.string,
    background: PropTypes.string,
    border: PropTypes.string
}

export {
    CardGlassContainer,
    Card
}