import React from 'react'
import { Switch, Redirect, Route } from "react-router-dom"
import BaseRoute from 'app/BaseRoute'

export function Routes() {
    return (
        <Switch>
            <Route path="/" component={BaseRoute} />
            <Redirect to="/"/>
        </Switch>
    )
}