import React, { useState, useEffect, useRef } from 'react'
import { BiChevronDown, BiSearchAlt2, BiPlus } from 'react-icons/bi'
import { VscArchive } from 'react-icons/vsc'

function SelectSearch({ placeholder, datalist = [], defaultValue = null, rounded = '', size = '', onSelected = null, onCreateItem = null, isCreate = false, ...rest }) {
    const wrapperRef = useRef(null)
    const [selectTitle, setSelectTitle] = useState('Select')
    const [dataState, setDataState] = useState([])
    const [stateDefaultValue, setStateDefaultValue] = useState(null)
    const [elementState, setElementState] = useState(null)
    const [swapIcon, setSwapIcon] = useState(false)
    const [selectOptions, setSelectOption] = useState({
        list_heigth: '-99999px',
        visible: false
    })

    useEffect(() => {
        if (placeholder) {
            setSelectTitle(placeholder)
        }
    }, [placeholder])

    useEffect(() => {
        if (placeholder) {
            setSelectTitle(placeholder)
        } else {
            setSelectTitle('Select')
        }
        
        if (datalist.length > 0) {
            setDataState(datalist)
            initDefaultValues()
        }
    }, [datalist])

    function onClickSelect(el) {
        el.currentTarget.querySelectorAll('.select-selection-item')[0].classList.add('select-hide-label')
        el.currentTarget.querySelectorAll('input')[0].focus()
        el.currentTarget.querySelectorAll('input')[0].placeholder = selectTitle
        setElementState(el.currentTarget)
        setSelectOption({ list_heigth: '200px', visible: true })
        setSwapIcon(true)
    }

    function handleOutside() { }

    function handleResetSelect(el) {
        el.querySelectorAll('.select-selection-item')[0].classList.remove('select-hide-label')
        el.querySelectorAll('input')[0].placeholder = ''
        el.querySelectorAll('input')[0].value = ''
        setElementState(null)
        setSelectOption({ list_heigth: '-99999px', visible: false })
        setSwapIcon(false)

        if (datalist.length > 0) {
            setDataState(datalist)
        }
    }

    function initDefaultValues() {
        if (defaultValue != null) {
            let index = datalist.findIndex(x => x.id == defaultValue)

            if (index > -1) {
                setStateDefaultValue(datalist[index])
                setSelectTitle(datalist[index].label)
            }
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                handleOutside && handleOutside()

                if (elementState !== null) {
                    handleResetSelect(elementState)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)

        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [handleOutside])

    function onSelectValue(item) {
        setStateDefaultValue(item)

        if (onSelected !== null) {
            onSelected(item)
        }

        setSelectTitle(item.label)
        handleResetSelect(elementState)
    }

    function onSearch(event) {
        let data = [...datalist]
        let search = filterArrayObj(event.target.value, data, 'label')

        setDataState(search)
    }

    function onHandleCreateItem() {
        if (onCreateItem != null) {
            onCreateItem()
        }
    }

    function getRounded() {
        switch (rounded) {
            case 'sm':
                return ' rounded-sm'
            case 'md':
                return ' rounded-md'
            case 'lg':
                return ' rounded-lg'
            case 'xl':
                return ' rounded-xl'
            case '2xl':
                return ' rounded-2xl'
            default:
                return ''
        }
    }

    function getSize() {
        switch (size) {
            case 'sm':
                return ' text-[12px] my-[3px]'
            default:
                return ''
        }
    }

    function getPlaceholderSize() {
        switch (size) {
            case 'sm':
                return ' text-[12px]'
            default:
                return ''
        }
    }

    return (
        <section ref={wrapperRef}>
            <div className="select-base">
                <div className="select-base-selector">
                    <div className="select-base-contenter" onClick={(e) => onClickSelect(e)}>
                        <span className="select-selection-search">
                            <input className={`select-base-input${getRounded()}${getSize()}`} onChange={(e) => onSearch(e)}></input>
                        </span>
                        <span key={stateDefaultValue} className={`select-selection-item${getPlaceholderSize()}`} title={selectTitle}>
                            {selectTitle}
                        </span>
                        <span className="select-icon-suffix">
                            {swapIcon ? <BiSearchAlt2 size={25} /> : <BiChevronDown size={25} />}
                        </span>
                    </div>
                    <div className="select-base-option">
                        <div key={selectOptions.list_heigth} className={` z-[60] transition-opacity overflow-auto delay-200 duration-500 w-full bg-white ${selectOptions.visible ? ' shadow-lg border border-gray-200' : ''}`} style={{ maxHeight: '300px', height: selectOptions.list_heigth < 0 ? selectOptions.list_heigth : 'unset' }}>
                            {swapIcon ?
                                <div>
                                    {
                                        dataState.length > 0 ?
                                            dataState.map(function (item, i) {
                                                return (
                                                    <div onClick={() => onSelectValue(item)} key={item.id + '_' + i} className="flex flex-col">
                                                        <div className={`select-base-option-option ${stateDefaultValue != null ? stateDefaultValue.id === item.id ? 'select-base-option-item-selected' : 'select-base-option-item' : 'select-base-option-item'}`}>
                                                            <div className="select-item-option-content">{item.label}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : <div className="flex flex-col">
                                                <div className={`select-base-option-option select-base-option-item`}>
                                                    <div className="w-full items-center">
                                                        <div className="flex justify-center">
                                                            <VscArchive size={40} />
                                                        </div>
                                                        <div className="flex justify-center">
                                                            <div className="select-item-option-content text-center text-font-gray">ไม่พบข้อมูล</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                                : null}
                        </div>
                        {swapIcon && isCreate ?
                            <div className="flex flex-col shadow-lg border border-gray-200 rounded-b-md" onClick={onHandleCreateItem}>
                                <div className="select-base-option-option select-base-option-item-addon rounded-b-md">
                                    <div className="select-item-option-content text-cny-primary" style={{ display: 'inline-flex' }}>
                                        <span className="pr-2" style={{ paddingTop: '2px' }}>
                                            <BiPlus size={20} color="#379FFF" />
                                        </span>
                                        เพิ่มข้อมูล
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>

            </div>
        </section>
    )
}

function filterArrayObj(searchQuery, array, objectKey = null) {
    if(searchQuery !== null && searchQuery !== undefined && searchQuery !== '' && objectKey !== null) {
        let _response = array.filter(item => item[objectKey].toLowerCase().indexOf(searchQuery) > -1)

        return _response
    } else {
        return array
    }
}

export default SelectSearch