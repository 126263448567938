import React, { createContext, useContext, useState } from 'react'
import { node } from 'prop-types'

const RBACContext = createContext({})
const initRBAC = {
    roles: []
}

export function useRBACContext() {
    return useContext(RBACContext)
}

function RBACProvider({ children }) {
    const [role, setRoles] = useState(initRBAC)
    const RBACStore = {
        role,
        RBACAction: {
            setRoleToRBAC
        }
    }

    function setRoleToRBAC(roles) {
        setRoles({
            roles: roles
        })
    }

    return (
        <RBACContext.Provider value={RBACStore}>
            {children}
        </RBACContext.Provider>
    )
}

RBACProvider.prototype = {
    children: node.isRequired
}

export default RBACProvider