import { all, fork, takeLatest } from 'redux-saga/effects'
import { handleGetStationActivities, handleCUDActivity, handleGetActivities } from '@core/redux/sagas/workers/station/ActivitiesHandler'
import { getStationActivities, CUDActivities, getActivities } from '@core/redux/reducers/station/ActivitiesSlice'

function* watchGetStationActivities() {
    yield takeLatest(getStationActivities.type, handleGetStationActivities)
}

function* watchCUDActivity() {
    yield takeLatest(CUDActivities.type, handleCUDActivity)
}

function* watchGetActivities() {
    yield takeLatest(getActivities.type, handleGetActivities)
}

export default function* ActivitiesWatcher() {
    const watchers = [
        watchGetStationActivities,
        watchCUDActivity,
        watchGetActivities
    ]

    yield all(watchers.map((watcher) => fork(watcher)))
}