import styled, { css } from 'styled-components'

const ClearFixDivStyle = styled.div`
    &:after {
        content: "."; 
        display: block; 
        height: 0; 
        clear: both; 
        visibility: hidden;
    }
`

const ClearFixDiv = (props) => <ClearFixDivStyle {...props} />

export {
    ClearFixDiv
}