import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    total_count: 0,
    total_size: 0,
    result_list: null,
    result: null,
    isLoading: false,
    isSuccess: false,
    error: null
}

const LevelSlice = createSlice({
    name: 'level',
    initialState,
    reducers: {
        getLevel: state => {
            state.isLoading = true
        },
        getLevelSuccess: (state, { payload }) => {
            state.isLoading = false
            state.result_list = payload.result_list
        },
        getLevelError: (state, { payload }) => {
            const { error } = payload

            state.isLoading = false
            state.error = error
        },
        clearSignal: state => {
            state.isSuccess = false
            state.error = null
        },
        clearData: state => {
            state.isSuccess = false
            state.error = null
            state.total_count = 0
            state.total_size = 0
            state.result_list = null
            state.result = null
        }
    }
})

export const {
    getLevel,
    getLevelSuccess,
    getLevelError,
    clearSignal,
    clearData
} = LevelSlice.actions

export default LevelSlice.reducer